import $ from 'jquery';
import store from '../../_src/store';
import {disableScroll, enableScroll} from '../../_src/disableScroll.js'

store.$app.on('click','#site-nav__toggle', function(e) {
    e.preventDefault();

    const $this = $(this),
          $menu = $('#site-nav');

    $this.toggleClass('active');
    store.$app.removeClass('sub-nav-shown');

    if($this.hasClass('active')) {
        $menu.addClass('active');
        disableScroll();
    } else {
        $menu.removeClass('active');
        enableScroll();

        $('#site-nav__main .menu-item-has-children').removeClass('active');
    }
});

store.$app.on('click', '#site-nav__main .menu-item-has-children > a', function(e) {
    e.preventDefault();

    const $this = $(this),
          $menu = $('#site-nav__main'),
          $parent = $this.closest('.menu-item'),
          active  = !$parent.hasClass('active');

    $menu.find('.menu-item-has-children').not($parent).removeClass('active');

    if(active) {
        store.$app.addClass('sub-nav-shown');
        $parent.addClass('active');
    } else {
        window.location = this.getAttribute('href');
    }
});

store.$app.on('click', '#site-nav__main .site-nav__back', function(e) {
    e.preventDefault();

    const $this = $(this),
          $menu = $this.closest('.menu-item-has-children');

    $menu.removeClass('active');
    store.$app.removeClass('sub-nav-shown');
})