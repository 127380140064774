import $ from 'jquery';
import store from './_src/store';
import {viewport} from './_src/responsive';

import'./_src/visibility';
import './_src/popup-window';

import './templates/age-verification';

import './templates/site-nav/';
import './templates/social-items/';
import './templates/blog/';
import './templates/whisky/';
import './templates/opening-times/';
import './templates/map/';
import './templates/process/';
import './templates/timeline/';
import {resetHover} from './templates/filter/';


viewport();
store.$window.on('resize', viewport)


$('[data-select-redirect]').on('change',function(e) {
  e.preventDefault()

  window.location.href = $(this).val()
});

window.WebFontConfig = {
    google: {
        families: ['Cormorant Garamond:400,500,500i,600,600i','Tenali Ramakrishna'],
    },
    active: function() {
        $('html').removeClass('fonts-loading');
        setTimeout(() => { resetHover(); }, 100);
    },
};

setTimeout(() => {
    $('html').removeClass('fonts-loading');
}, 3000);

(function() {
    var wf = document.createElement('script');
    wf.src = ('https:' == document.location.protocol ? 'https' : 'http') +
    '://cdnjs.cloudflare.com/ajax/libs/webfont/1.6.27/webfontloader.js';
    wf.type = 'text/javascript';
    wf.async = 'true';
    var s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(wf, s);
})();

$('input, select, textarea').on('keyup change',function() {
    $(this).closest('.wpcf7-form-control-wrap').find('.wpcf7-not-valid-tip').fadeOut(250, function() {
        $(this).remove();
    });
});


store.$app.on('click','[data-scroll-to]', function(e) {
    e.preventDefault();
    
    const elem = this.getAttribute('data-scroll-to') || this.getAttribute('href'),
          action = this.getAttribute('data-scroll-position') || 'normal';
          
    var top = 0;
    
    if($(elem)[0]) {
        top = $(elem).offset().top;
        
        if(action === 'after') {
            top += $(elem)[0].offsetHeight;
        }
    }
    
    $('html, body').animate({
      scrollTop: top
    }, 1366);
});