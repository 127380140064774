import $ from 'jquery'
import store from './store'

const defaultOptions = {
  location: 'no',
  status: 'no',
  menubar: 'no',
  toolbar: 'no',
  resizable: 'yes',
  scrollbars: 'yes',
  width: 750,
  height: 227
}

store.$app.on('click', '[data-sharing]', function(e) {
  e.preventDefault()
  const $this = $(this)
  const href = String(this.getAttribute('href'));
  const options = Object.assign({}, defaultOptions, $this.data())

  const strOptions = Object.keys(options)
    .map(key => `${key}=${options[key]}`)
    .join(',')

  window.open(href, '', strOptions)
})
