import $ from 'jquery';
import store from '../../_src/store';
import {disableScroll, enableScroll} from '../../_src/disableScroll.js'

const key   = 'age-verification';
const value = 'success';

const template = $('#age-template').html();

if(sessionStorage.getItem(key) !== value && localStorage.getItem(key) !== value) {
    store.$app.append(template);
    disableScroll();

    store.$app.on('click','#age__yes', function(e) {
        e.preventDefault();

        const method = $('#age__remember').is(':checked') ? window.localStorage : window.sessionStorage;
        method.setItem(key, value);

        $('body > #age-verification').fadeOut(300, function() {
            enableScroll();
            $(this).remove();
        });
    });

    store.$app.on('click','#age__no', function(e) {
        e.preventDefault();

        window.location.href = 'https://www.drinkaware.co.uk';
    });
}