import $ from 'jquery'

export default {
  resolution: window.devicePixelRatio || 1,
  $document: $(document),
  $window: $(window),
  $app: $('body'),
  body: document.getElementsByTagName('body')[0],
  breakpoints: {
    active: [],
    points: {
      'xsmall':     '(max-width: 639px)',
      'small':      '(min-width: 640px)  and (max-width: 767px)',
      'small-down': '(max-width: 767px)',
      'medium-up':  '(min-width: 768px)',
      'medium':     '(min-width: 768px)  and (max-width: 1023px)',
      'medium-down':'(max-width: 1023px)',
      'large-up':   '(min-width: 1024px)',
      'large':      '(min-width: 1024px)  and (max-width: 1279px)',
      'xlarge':     '(min-width: 1280px)'
    }
  },
  google: {
    key: 'AIzaSyAtRyeLluCuBtMjZBdnK69cgpjJOFTChac'
  }
}
