import $ from 'jquery'
import store from '../../_src/store';
import 'featherlight';

const videos = window.videos;

store.$app.on('click','a[data-video]', function(e) {
  const element = this.getAttribute('data-video');

  if(typeof videos[element] === 'undefined') {
      return true;
  }

  e.preventDefault();
  $.featherlight(videos[element]);

  return false;
});
