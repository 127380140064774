import $ from 'jquery';
import store from './store';

$.fn.visible = function(partial) {
    var $t            = $(this),
        $w            = $(window),
        viewTop       = $w.scrollTop(),
        viewBottom    = viewTop + $w.height(),
        _top          = $t.offset().top,
        _bottom       = _top + $t.height(),
        compareTop    = partial === true ? _bottom : _top,
        compareBottom = partial === true ? _top : _bottom;

    return ((compareBottom <= viewBottom) && (compareTop >= viewTop));
}

const selectors = [
    '.site-main > *',
    '.site-main > .page > *',
    //'.timeline__item',
    '.signature__paths',
    '.process__item'
];

const selectorString = selectors.join(', ');

$(window).on('scroll resize', () => {
    checkVisibility();
});

checkVisibility();

let length = null;
let paths  = null;
let current_path = 0;
let path = null;

function checkVisibility() {
    $(selectorString).each(function() {
        const $this = $(this);

        if($this.visible(true)) {
            $this.addClass('visible');
        } else {
            $this.removeClass('visible');
        }
    })

    $('.signature__paths').each(function() {
        const $this = $(this);

        if($this.visible(true)) {
            drawPaths($this);            
        } else {
            $this.data('animation-done', false);
        }
    });
}

function drawPaths(elem) {
    const $this = $(elem),
          $paths = $this.find('path');
    
    if(!$this.hasClass('visible') || $this.data('animation-done') == true) { return true; }

    length       = null;
    current_path = 0;
    path         = null;

    $paths.each(function() {
        this.style.strokeDasharray  = this.getTotalLength();
        this.style.strokeDashoffset = this.getTotalLength();
    });

    paths = $paths;
    
    requestAnimationFrame(draw);

    $this.data('animation-done', true);
}

function draw(e = null) {
    if(!path) { path = paths[current_path]; }
    if(!length) { length = path.getTotalLength(); }

    length = length - 5;
    length = length < 0 ? 0 : length;

    path.style.strokeDashoffset = length;

    if(length <= 0) { current_path = current_path + 1; path = null; }
    if(current_path < paths.length) {
        requestAnimationFrame(draw);
    }
}