import $ from 'jquery';
import style from './styles';
import store from '../../_src/store';
import loadGoogleMapsAPI from 'load-google-maps-api';

const $map = $('#map');
const $markers = $map.find('[data-marker]');

let settings = {
    scrollwheel       : false,
    mapTypeControl    : false,
    streetViewControl : false,
    zoom              : 17,
    styles            : style,
}

let map;
let api;

let marker;
let markers = {};
let bounds;

if($map[0] && $markers[0]) {

    loadGoogleMapsAPI({
        key: store.google.key,
    }).then(mapsAPI => {

        loadMap(mapsAPI);

    }).catch(err => {
        console.error(err);
    });

}

function loadMap(mapsAPI) {
    api = mapsAPI;
    bounds = new api.LatLngBounds();

    var lat = parseFloat($markers[0].getAttribute('data-lat'));
    var lng = parseFloat($markers[0].getAttribute('data-lng'));

    if($markers.length == 1) {
        settings.center = new api.LatLng({lat, lng});
    }

    map = new api.Map($map[0], settings);

    marker = {
        size       : new api.Size(90, 105),
        origin     : new api.Point(0, 0),
        anchor     : new api.Point(45, 86),
        scaledSize : new api.Size(90, 105),
        url        : '/app/themes/madebrave/assets/img/map-marker@2x.png',
    };

    $markers.each(function(index) {
        var lat = parseFloat(this.getAttribute('data-lat'));
        var lng = parseFloat(this.getAttribute('data-lng'));
        var url = this.getAttribute('data-marker') || false;

        var mapMarker = marker;

        if(url) {
            mapMarker.url = url;
        }

        markers[index] = new api.Marker({
            map,
            position: new api.LatLng({lat,lng}),
            icon: marker,
            id: index,
        });

        if($markers.length > 1) {
            bounds.extend({lat,lng});
            map.fitBounds(bounds);
        }
    });
}