import $ from 'jquery';
import store from '../../_src/store';

store.$app.on('click', '.tab__nav__item a', function(e) {
    e.preventDefault();

    const nav  = $(this).closest('.tab__nav'),
          tab  = $(this).closest('.tab'),
          link = this.getAttribute('href');

    nav.find('.tab__nav__item').removeClass('active');
    tab.find('.tab__item').removeClass('active');
    tab.find(link).addClass('active');
    $(this).closest('.tab__nav__item').addClass('active');
});