import $ from 'jquery';
import store from '../../_src/store';


const timeline = $('.timeline'),
      items    = timeline.find('.timeline__item');

let top,
    height,
    offset = window.pageYOffset,
    pHeight = window.innerHeight,
    visTicking = false,
    higTicking = false,
    min = 0;

function getHeight() {
    if(higTicking == true) { return false; }
    higTicking = true;

    offset = window.pageYOffset;
    pHeight = window.innerHeight;

    height = Math.max(min, (offset + (pHeight * 0.5)) - top);

    $('.timeline__progress').css('height',height+'px');

    higTicking = false;
}

function setTop() {
    top = $('.timeline__progress').offset().top;

    items.each((index, item) => {
        var $this = $(item);

        $this.data('position-top', $this.find('.timeline__content time')[0].offsetTop + 23.5);

        if(index == 0) {
            min = $this.data('position-top');
        }
    });
}

function getVisible() {
    if(visTicking == true) { return false; }
    visTicking = true;

    items.each((index, item) => {
        var $this = $(item),
            posTop = null; //$this.data('position-top');

        if(!posTop) {
            posTop = $this.find('.timeline__content time')[0].offsetTop + 23.5;
            $this.data('position-top', posTop);
        }

        if(height >= posTop) {
            $this.addClass('visible');
        } else {
            $this.removeClass('visible');
        }
    });

    visTicking = false;
}

if(timeline[0]) {
    timeline.prepend('<div class="timeline__progress" aria-hidden="true"></div>');

    setTop();
    getHeight();
    getVisible();

    store.$window.on('resize', function() {
        setTop();
    });

    store.$window.on('scroll resize', function() {
        getHeight();
        getVisible();
    });

}