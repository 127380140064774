import moment from 'moment';
import $ from 'jquery';

export var getOpeningTimes = function(openingTimes) {
  const now = moment().tz('Europe/London');

  const day = now.isoWeekday() - 1,
        hour    = now.hours(),
        minute  = now.minutes(),
        seconds = now.seconds();

  var lastDay   = openingTimes.length - 1,
      lastTimes = openingTimes[lastDay];

  var activeDay    = day,
      isOpen       = false,
      message      = 'Closed';

  $.each(openingTimes, function(index, times) {
    if(index !== activeDay) { return true; }

    activeDay = index,
    isOpen = false;

    if(
        times.is_open &&
        (
          (
           hour >= times.opens.h &&
           (
             (hour == times.opens.h && minute >= times.opens.m) || (hour >= times.opens.h)
           ) &&
           (
             (hour == times.opens.h && minute == times.opens.m  && seconds >= times.opens.s) || (hour >= times.opens.h || (hour == times.opens.h && minute > times.opens.m))
           )
          ) &&
          (
            hour <= times.closes.h &&
            (
              (hour == times.closes.h && minute <= times.closes.m) || (hour <= times.closes.h)
            ) &&
            (
              (hour == times.closes.h && minute == times.closes.m && seconds <= times.closes.s) || (hour < times.closes.h || (hour == times.closes.h && minute < times.closes.m))
            )
          )
        )
      ) {

      message = 'Open';

    } else  {

      message = 'Closed';

    }

  });

  $('#opening-times__message').text(message);
}


$('[data-time]').each(function() {
    const h = this.getAttribute('data-h'),
          m = this.getAttribute('data-m');

    var time = moment(),
        zone = time.utcOffset();

    console.log(h, m, zone);

    time.tz('Europe/London').hour(h).minute(m);
    time.utcOffset(zone);

    console.log(time.minute());

    var format = 'h'

    if(time.minute() > 0) {
        format += '.mm'
    }

    format += 'a';

    this.innerHTML = time.format(format);
});