import $ from 'jquery';
import 'owl.carousel';
import store from '../../_src/store';

let latestPosts;
let active = typeof($('.blog-latest')[0]) !== 'undefined';
let activeSize = $('.blog-latest').attr('data-active-size') || 'xsmall';

function createCarousel() {
    if(!active || typeof(latestPosts) !== 'undefined') { return true; }

    latestPosts = $('.blog-latest').owlCarousel({
        items  : 1,
        center : true,
        dots   : true,
        nav    : false,

        onInitialized: (function(e) {
            this.$element.addClass('owl-carousel');
            this.$element.find('.cloned').attr({
                'aria-hidden': true,
                'role'       : 'presentation',
            });
        })
    });
}

function destroyCarousel() {
    if(!active || typeof(latestPosts) === 'undefined') { return true; }

    latestPosts.trigger('destroy.owl.carousel').removeClass('owl-carousel');
    latestPosts = undefined;
}

store.$app.on('breakpoint-change',function() {

    if(store.breakpoints.active.indexOf(activeSize) > -1) {
        createCarousel();
    } else {
        destroyCarousel();
    }

});