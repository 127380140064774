import $ from 'jquery';

const $hovers       = $('[data-active-hover]'),
      hoverClass    = 'moving-hover',
      hoverSelector = '.'+hoverClass;

let hoverTimeout,
    animationTimeout;

if($hovers[0]) {
    $hovers.each(function() {
        const $this = $(this),
              activeClass = this.getAttribute('data-active-hover') || 'active';

        var $links = $this.find('a, .'+activeClass),
            excludes    = this.getAttribute('data-active-hover-exclude') || false;

        if(excludes) {
            excludes = excludes.split(' ');

            $(excludes).each((i, exclude) => {
                $links = $links.filter(':not(.'+exclude+')');
            });
        }

        if(!$this.find(hoverSelector)[0]) {
            $this.prepend('<div class="'+hoverClass+'"></div>');
        }

        const $hover = $this.find(hoverSelector);

        $links.on('mouseenter', function() {
            const $active = $(this),
                  $elem   = $active.closest('[data-active-hover]'),
                  bounds  = getBounds($elem, $active);

            clearTimeout(hoverTimeout);
            moveHover($elem, bounds);
        }).on('mouseleave', function() {
            const $elem = $(this).closest('[data-active-hover]'),
                  activeClass = $elem.attr('data-active-hover') || 'active';

            hoverTimeout = setTimeout(() => {
                setActiveFilter($elem, activeClass);
            }, 250);
        });
    });

    resetHover();
}

export function resetHover() {
    $hovers.each(function() {
        const $this = $(this),
              activeClass = this.getAttribute('data-active-hover') || 'active';

        setActiveFilter(this, activeClass);
    });
}

function setActiveFilter(elem, activeClass = null) {
    if(activeClass === null) {
        activeClass = $(elem).attr('data-active-hover') || 'active';
    }

    const $this = $(elem),
          active = $this.find("."+activeClass),
          bounds = getBounds(elem, active);

    moveHover(elem, bounds);
}

function moveHover(elem, bounds) {
    const $this = $(elem),
          $hover = $this.find(hoverSelector);

    clearTimeout(animationTimeout);

    $hover.addClass('moving').css({
        'left'  : bounds.left,
        'right' : bounds.right,
    });

    animationTimeout = setTimeout(() => {
        $hover.removeClass('moving');
    }, 333);
}

function getBounds(elem, active) {
    const $elem = $(elem),
          $active = $(active);

    var position = $active.position();
    position.right = $elem[0].offsetWidth - (position.left + parseInt($active.innerWidth()));

    return position;
}

$(window).on('resize', function() {
    $(hoverSelector).css('transition','none');
    resetHover();
    $(hoverSelector).css('transition','');
});