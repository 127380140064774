import $ from 'jquery';
import 'owl.carousel';
import store from '../../_src/store';

let current = 0;
let carouselClass = '.whisky-carousel';

$(carouselClass).each(function() {
    const $this = $(this);
    var start   = this.getAttribute('data-carousel-start') || null;
          
    start = start ? $this.find('[data-carousel-id="'+start+'"]').index() : 0;

    $this.owlCarousel({
        items  : 1,
        dots   : false,
        nav    : true,
        loop   : false,
        slideBy: 'page',
        mouseDrag: false,
        startPosition: start,
        responsive : {
            0 : {
                items: 1,
            },
            480 : {
                items: 2,
            },
            1024 : {
                items: 3
            }
        },
        onInitialized: (function(e) {
            this.$element.addClass('owl-carousel');
            this.$element.find('.cloned, .owl-nav').attr({
                'aria-hidden': true,
                'role'       : 'presentation',
            });
    
            updateCount(e, this, this.$element);
        })
    });
});


$(carouselClass).on('changed.owl.carousel',function(e) {
    const $elem    = $(this),
          $data    = $elem.data('owl.carousel');
    updateCount(e, $data, $elem);
}).on('translate.owl.carousel',function(e) {
    $(this).addClass('owl-translating');
}).on('translated.owl.carousel',function(e) {
    $(this).removeClass('owl-translating');
}).on('click','.owl-prev', function(e) {
    $(this).siblings('.owl-count').addClass('owl-count--prev');
}).on('click','.owl-next', function(e) {
    $(this).siblings('.owl-count').removeClass('owl-count--prev');
});

function updateCount(e, $data, $elem) {

    var numItems = Math.ceil(e.item.count / e.page.size),
        curItem  = Math.ceil(($data._current - ($data._clones.length / 2)) / e.page.size) + 1;

    curItem = (curItem > numItems) ? 1 : Math.max(1,curItem);

    if($elem.find('.owl-nav .owl-count')[0]) {
        $elem.find('.owl-nav .owl-count').html(curItem+'<span>/</span>'+numItems);
    } else {
        $elem.find('.owl-nav .owl-prev').after('<div class="owl-count" aria-hidden="true" role="presentation">'+curItem+'<span>/</span>'+numItems+'</div>');
    }

}