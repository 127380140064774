import store from './store'

export function viewport() {
  const w      = window,
        de     = document.documentElement,
        width  = w.innerWidth  || de.clientWidth  || store.body.clientWidth,
        height = w.innerHeight || de.clientHeight || store.body.clientHeight;

  var   changed = false;

  store.viewport = {width, height}
  store.$app.trigger('app-resize');

  store.breakpoints.active = new Array();

  for(const name in store.breakpoints.points) {
    if (!store.breakpoints.points.hasOwnProperty(name)) continue;

    var query = store.breakpoints.points[name];

    if(window.matchMedia(query).matches) {
      changed = true;

      store.breakpoints.active.push(name);
      store.$app.trigger('breakpoint-change-'+name);
    }
  }

  if(changed) {
      store.$app.trigger('breakpoint-change');
  }
}