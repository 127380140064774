import moment from 'moment';
import 'moment-timezone';
import $ from 'jquery';

import {getOpeningTimes} from '../times';

const date = moment().tz('Europe/London');

const seconds = date.seconds();
const minutes = date.minutes();
const hours   = date.hours() > 12 ? date.hours() - 12 : date.hours();

let hands = {
    'hours' : (hours * 30) + (minutes / 2),
    'minutes' : (minutes * 6),
    'seconds' : (seconds * 6),
};


/*
 * Starts any clocks using the user's local time
 */
function initClocks() {
    $.each(hands, (hand, angle) => {
        $('.clock__hand--'+hand).css('transform', 'translateX(-0.5px) rotateZ('+angle+'deg)');
    });

    setTimeout(() => {
        $('.clock').addClass('active');
    }, 10);

    moveSecondHands();
    setUpMinuteHands();
}

/*
 * Move the second containers
 */
function moveSecondHands() {
    const $seconds = $('.clock__hand--seconds');

    setInterval(() => {
        hands.seconds = (hands.seconds || 0) + 6;

        $seconds.css('transform', 'translateX(-0.5px) rotateZ('+hands.seconds+'deg)')

    }, 1000);
}

function setUpMinuteHands() {
    const seconds = hands.seconds || 0;
    const delay   = (((360 - seconds) / 6) + 1) * 1000;

    setTimeout(moveMinuteHands, delay);
}

function moveMinuteHands() {
    const $minutes = $('.clock__hand--minutes');
    const $hours   = $('.clock__hand--hours');

    hands.minutes  = (hands.minutes || 0) + 6;
    hands.hours    = (hands.hours   || 0) + 0.5;

    $minutes.css('transform',  'translateX(-0.5px) rotateZ('+hands.minutes+'deg)');
    $hours.css('transform',  'translateX(-0.5px) rotateZ('+hands.hours+'deg)');

    if(typeof window.openingTimes == 'object') {
      getOpeningTimes(window.openingTimes);
    }


    setTimeout(moveMinuteHands, 60000);
}



initClocks();